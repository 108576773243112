import React from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import bannerImage from "../../../media/salon-b754cc7f81c93ba9bf4552483d1bd68a-min.jpg"

const StaticImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(
    graphql`
      query {
        django2 {
          allCollections(slug: "static") {
            edges {
              node {
                dressSet(name: "header") {
                  edges {
                    node {
                      name
                      dresspictureSet {
                        edges {
                          node {
                            originalPhoto
                            originalPhotoSharp {
                              childImageSharp {
                                fluid(maxWidth: 1920) {
                                  ...GatsbyImageSharpFluid
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return (
   // <Img
   //   fluid={
   //     data.django2.allCollections.edges[0].node.dressSet.edges[0].node
   //       .dresspictureSet.edges[0].node.originalPhotoSharp.childImageSharp
   //       .fluid
   //   }
   //   alt={
   //     data.django2.allCollections.edges[0].node.dressSet.edges[0].node.name
   //   }
   // />
	  <img src={bannerImage} alt="banner" />
  );
};
export default StaticImage;
